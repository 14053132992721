import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const IndexPage = () => (
  <Layout>
    <Seo title="Our Services for Adults" />

    <div className="masthead mb-5">
      <Container className="singleCol text-center">
        <h1>Our Services for Adults</h1>
      </Container>
    </div>
    
    <Container>
      <Row className="g-5">

      <Col lg={6}>
          <p className="text-center">
          <StaticImage
            src="../../images/adult-business-man.jpg"
            loading="eager"
            height={375}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Adult business man"
          />  
          </p>
          <h2>Adults</h2>
          <p>
          Vibe speech therapists use their clinical expertise and experience in supporting adults in all matters related to speech and language: articulation, language, voice, accent modification, recovery from a stroke, head trauma, or as a supportive therapy while coping with a neurological disorder. We help clients, families and caregivers to support their loved ones in recovering their speech and language skills.   
          </p>

          <p className="text-center">
          <StaticImage
            src="../../images/accent-mod-group.jpg"
            loading="eager"
            width={600}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="3 people sitting around a cafe table"
          />  
          </p>
          <h2>Accent Modification</h2>
          <p>
            When we learn another language, we try to perfect speech patterns, intonations, and non-verbal communication specific to that language, but we are often limited in reaching our goal of fluency. Accents sometimes make it difficult to communicate with other people, and can make work and other activities more challenging. 
          </p>
          <p>
            If you are seeking accent modification, you may be looking to have more successful business and personal relationships. People choose to modify their accent for public speaking, acting, giving clear and engaging presentations, improving business management skills and professional communication, or demonstrating improved leadership skills.   
          </p>
        </Col>
        
        <Col lg={6}>
          <p className="text-center">
            <StaticImage
              src="../../images/progress-pride-flag.jpg"
              loading="eager"
              height={375}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Progress pride flag"
            />  
          </p>
          <h2>Gender Affirming Voice Therapy</h2>
          <p>
            Individual virtual voice therapy sessions are available for anyone wanting to change their voice to match their gender expression. Sessions are tailored to our clients’ needs and strengths with privacy and flexibility. Our client relationships are at the center of everything we do. Our relationship with you is as important as your speech therapy goals.    
          </p>
          <p>
            Gender affirming voice group sessions are available for adolescents and adults.  Our virtual group sessions are a fun and safe way to learn about and practice your ideal voice in a casual environment. Whether you're just getting started on your voice journey, or you've been working with it for several years,  group sessions are an excellent way to hone your skills and connect with others. 
          </p>
          <p>
            Guided by our gender affirming voice specialist and speech language pathologist, Eliana Rivera M.S., CCC-SLP, you'll have the opportunity to ask questions, practice using your voice safely with new people, and hopefully make a new friend or two. The goal of this group is to teach and learn from each other. 
          </p>
          <p>
            Available to current and prospective clients. Session topics will vary based on attendees and may include but are not limited to: conversation practice, specific pitch and/or resonance work, tension exercises, nonspeaking components of communication (e.g. intonation and/or body language), and vocal hygiene education.
          </p>
          <p>
          A minimum of 4 people is required for a full 1 hour group session. If less than 4 people attend, group will still run but time and/or topics will be adjusted. Vibe Speech Therapy reserves all rights to adjust group sessions as needed.
          </p>
          <hr />
          <p>To sign up, please complete these two steps</p>
          <p>Step 1:</p>
          <p><a href="https://buy.stripe.com/6oEg2weGhgwC2sM7ss" className="btn btn-dark">Sign Up & Purchase</a></p>
          <p>Step 2:</p>
          <p><Link to="/services/gender-affirming-group-form/" className="btn btn-dark">Complete the agreement form</Link></p>
          <hr />
          <p>Virtual Zoom sessions are on the last Tuesday of the month at 6pm PST. </p>
          <p><em>Cancellation policy:  Notice of cancellation must be provided 48 hours in advance for all sessions in order to receive a refund. </em></p>
        </Col>
        

        
        
      </Row>
    </Container>
    
  </Layout>
)

export default IndexPage
